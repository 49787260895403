import Plan from "component/Landing/Plan/Plan";
import AuthContext from "contexts/authContext";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { createCheckoutSession, fetchSubscription } from "services/Auth";
import "./style.scss";
import Loading from "component/Loading";
import { useLocation } from "react-router-dom";

const Subscription = (props) => {
  const { inPopup = false } = props;
  const location = useLocation();
  const isFromCreateLesson =
    location.state && location.state.isFromCreateLesson;

  const { auth } = useContext(AuthContext);
  const [loader, setLoader] = useState(true);

  const fetchAndCheckSubscription = async () => {
    setLoader(true);
    if (
      _.get(auth, "userData.Subscription[0].status", "") === "active" ||
      _.get(auth, "userData.Subscription[0].status", "") === "trialing"
    ) {
      let subDetails = await fetchSubscription();
      const portalUrl = _.get(subDetails, "data.portalSessionUrl", "");
      if (portalUrl) {
        window.location.href = portalUrl;
      } else setLoader(false);
    } else setLoader(false);
  };
  const gotoCheckout = async (type, skipTrial) => {
    setLoader(true);
    const response = await createCheckoutSession(
      type,
      skipTrial,
      false,
      isFromCreateLesson ? true : false
    );
    const url = _.get(response, "data.url", "");
    if (url) {
      window.location.href = url;
    }
  };
  useEffect(() => {
    fetchAndCheckSubscription();
  }, [auth]);
  return loader ? (
    <Loading />
  ) : (
    <div className="subscription">
      <section className="pricing container">
        <h2
          id="pricing"
          style={{
            textAlign: "center",
            ...(inPopup
              ? { color: "black", fontSize: "36px", marginBottom: "10px" }
              : {}),
          }}
        >
          Upgrade to premium
        </h2>
        {inPopup && (
          <h4
            style={{
              textAlign: "center",
              fontSize: "14px",
              marginTop: "10px",
              marginBottom: "25px",
              fontWeight: "400",
            }}
          >
            You've hit your limit. Please choose from one of our paid plans to
            continue.
          </h4>
        )}
        <div className="pricing__plans" style={{ marginBottom: "50px" }}>
          {!_.get(auth, "userData.TrialTaken", false) && (
            <Plan
              headline="Starter Plan"
              description="Best if you are curious teacher and want to try Lessen AI for Free and see if it is for you."
              price="Free"
              benefits={[
                "1 lesson creation per day",
                "Valid for 7 days",
                "Lessons in line with Ofsted’s “outstanding” framework",
                "AI Generated images to suit the topic of your slides",
                "Claim back more than 10 hours per week of your time!",
                "Cancel Anytime",
              ]}
              btnText="Start >"
              btnLink="#"
              onClick={() => gotoCheckout("monthly", false)}
              className={_.get(auth, "userData.TrialTaken", false) ? 2 : 3}
            ></Plan>
          )}
          <Plan
            headline="Premium Plan"
            description="Best if you want to use Lessen AI to create professional presentations on a regular basis."
            price="£39/month"
            benefits={[
              "Unlimited Presentations",
              "Lessons in line with Ofsted’s “outstanding” framework",
              "AI Generated images to suit the topic of your slides",
              "Claim back more than 10 hours per week of your time!",
              "Cancel Anytime",
            ]}
            btnText="Select >"
            btnLink="#"
            onClick={() => gotoCheckout("monthly", true)}
            className={_.get(auth, "userData.TrialTaken", false) ? 2 : 3}
          ></Plan>
          <Plan
            headline="Annual Plan"
            description="Best if you want to use Lessen AI to create professional presentations on a regular basis, and save £48 a year"
            price="£35/month"
            benefits={[
              "Unlimited Presentations",
              "Lessons in line with Ofsted’s “outstanding” framework",
              "AI Generated images to suit the topic of your slides",
              "Claim back more than 10 hours per week of your time!",
              "Cancel Anytime",
            ]}
            btnText="Start >"
            btnLink="#"
            isSelected={true}
            onClick={() => gotoCheckout("annual", true)}
            className={_.get(auth, "userData.TrialTaken", false) ? 2 : 3}
          ></Plan>
        </div>
      </section>
    </div>
  );
};

export default Subscription;
