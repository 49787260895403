import axios from "axios";
import { endPoints } from "./endPoints";
import axiosInstance from "utils/axiosInstance";
import _ from "lodash";

export const generateLesson = async (
  exam_board = "",
  level = "",
  subjects = "",
  topic_name_title = "",
  specification_points = ""
) => {
  let config = {
    method: "post",
    url: endPoints.ai.generateLesson,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      exam_board,
      level,
      subjects,
      topic_name_title,
      specification_points,
    },
  };

  const response = await axiosInstance.request(config).catch((error) => {
    console.log(error);
    throw error;
  });
  return response;
};

export const generatePdf = async (html = "", fileName = "") => {
  let config = {
    method: "post",
    url: endPoints.ai.generatePdf,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      html,
      fileName,
    },
  };

  const response = await axiosInstance.request(config).catch((error) => {
    console.log(error);
  });
  return response;
};

export const generateResource = async (url, lessenId) => {
  let config = {
    method: "post",
    url: endPoints.resource.generateFromVideo,
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      url,
      lessenId,
    },
  };

  const response = await axiosInstance.request(config).catch((error) => {
    console.log(error);
  });
  return response;
};

export const getLessenResource = async (lessenId) => {
  let config = {
    method: "get",
    url: endPoints.resource.getLessenResource.replace(":lessenId", lessenId),
  };

  let res = await axiosInstance.request(config).catch((error) => {
    console.log(error);
  });
  return res;
};

export const getResource = async (resourceId) => {
  let config = {
    method: "get",
    url: endPoints.resource.getResource.replace(":resourceId", resourceId),
  };

  let res = await axiosInstance.request(config).catch((error) => {
    console.log(error);
  });
  return res;
};

export const updateLesson = async (
  id,
  updated_lesson,
  slide_id,
  section_id
) => {
  let config = {
    method: "post",
    url: endPoints.ai.updateLesson.replace(":id", id),
    headers: {
      "Content-Type": "application/json",
    },
    data: {
      updated_lesson,
      slide_id,
      section_id,
    },
  };

  const response = await axiosInstance.request(config).catch((error) => {
    console.log(error);
  });
  return response;
};

// export const updateLessonLastOpened = async (
//   id,
//   updated_lesson,
//   slide_id,
//   section_id
// ) => {
//   let config = {
//     method: "post",
//     url: endPoints.ai.updateLesson.replace(":id", id),
//     headers: {
//       "Content-Type": "application/json",
//     },
//     data: {
//       updated_lesson,
//       slide_id,
//       section_id,
//     },
//   };

//   const response = await axiosInstance.request(config).catch((error) => {
//     console.log(error);
//   });
//   return response;
// };

export const getLesson = async (id) => {
  let config = {
    method: "get",
    url: endPoints.ai.getLessonById.replace(":id", id),
  };

  let res = await axiosInstance.request(config).catch((error) => {
    console.log(error);
  });
  return res;
};
export const deleteLesson = async (id) => {
  let config = {
    method: "delete",
    url: endPoints.ai.deleteLessonById.replace(":id", id),
  };

  let res = await axiosInstance.request(config).catch((error) => {
    console.log(error);
  });
  return res;
};

export const getLessons = async (
  limit = 10000,
  offset = 0,
  search = "",
  orderBy = "createdAt",
  orderDirection = "ASC"
) => {
  let config = {
    method: "post",
    url: endPoints.ai.getLessons,
    data: {
      limit,
      offset,
      search,
      orderBy,
      orderDirection,
    },
  };

  let res = await axiosInstance.request(config).catch((error) => {
    console.log(error);
  });
  return res;
};

export const uploadImage = async (fileType, file) => {
  let config = {
    method: "get",
    url: endPoints.ai.getUploadImageUlr.replace(":fileType", fileType),
  };

  const res = await axiosInstance.request(config).catch((error) => {
    console.log(error);
  });

  await axios.put(res.data.uploadURL, file, {
    headers: {
      "content-type": file.type,
      "x-amz-acl": "public-read",
    },
  });
  return _.get(res.data.uploadURL.split("?"), "[0]", "");
};
