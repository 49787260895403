import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import CreateLessonPage1 from "./CreateLessonPage1";
import CreateLessonPage2 from "./CreateLessonPage2";
import { generateLesson } from "services/AI";
import { useNavigate } from "react-router-dom";
import { Paths } from "routes";
import Loading2 from "component/Loading2";
import _ from "lodash";
import AuthContext from "contexts/authContext";
import UnlimtedAccessForDialog from "dialogs/UnlimtedAccessForDialog";

function CreateLesson() {
  const [loading, setLoading] = useState(false);
  const [unlimtedAccessForDialogOpen, setUnlimtedAccessForDialogOpen] =
    useState(false);
  let lessonData = null;
  let defaultActivePage = 1;
  const anyLessonBeforePay = localStorage.getItem("hasLessonBeforePay");
  if (anyLessonBeforePay) {
    const lessonSpec = localStorage.getItem("lessonSpec");
    if (lessonSpec) {
      lessonData = JSON.parse(lessonSpec);
      if (
        _.get(lessonData, "lessonTitle", "").length > 0 &&
        _.get(lessonData, "subject", "").length > 0 &&
        _.get(lessonData, "studyLevel", "").length > 0 &&
        _.get(lessonData, "examBoard", "").length > 0
      )
        defaultActivePage = 2;
    }
  }

  const [data, setData] = useState(
    lessonData
      ? lessonData
      : {
          lessonTitle: "",
          subject: "",
          studyLevel: "",
          examBoard: "",
          keyComponents: [],
          specificPoints: "",
        }
  );
  const [activePage, setActivePage] = useState(defaultActivePage);
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!unlimtedAccessForDialogOpen) {
      localStorage.removeItem("hasLessonBeforePay");
      localStorage.removeItem("lessonSpec");
    }

    try {
      window.ttq.track("ClickButton", {
        contents: [
          {
            content_id: "create_lesson", // string. ID of the product. Example: "1077218".
            content_type: "ClickButton",
            content_name: "create_lesson", // string. The name of the page or product. Example: "shirt".
          },
        ],
        value: 1, // number. Value of the order or items sold. Example: 100.
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  if (loading) return <Loading2 />;
  return (
    <>
      {activePage === 1 && (
        <CreateLessonPage1
          data={data}
          changePage={(newData) => {
            setData({
              ...data,
              ...newData,
            });
            setActivePage(2);
          }}
        />
      )}
      {activePage === 2 && (
        <CreateLessonPage2
          data={data}
          changePage={() => {
            setActivePage(1);
          }}
          onSpecChang={(newData) => {
            setData({
              ...data,
              ...newData,
            });
            setActivePage(2);
          }}
          generateLessonClick={async (specificPoints = "") => {
            setLoading(true);
            try {
              const res = await generateLesson(
                _.get(data, "examBoard", "N/A")
                  ? _.get(data, "examBoard", "N/A")
                  : "N/A",
                data.studyLevel,
                data.subject,
                data.lessonTitle,
                specificPoints
              );
              if (res && res.data && res.data.jobId) {
                const jobId = res.data.jobId;
                navigate(
                  Paths.Presentation.replace(":jobid", jobId).replace(
                    ":mode",
                    "present"
                  )
                );
              }
            } catch (err) {
              console.log(err);
              localStorage.setItem("hasLessonBeforePay", true);
              localStorage.setItem("lessonSpec", JSON.stringify(data));
              if (!_.get(auth, "userData.TrialTaken", false)) {
                setUnlimtedAccessForDialogOpen(true);
              } else {
                setUnlimtedAccessForDialogOpen(true);
                // navigate(Paths.Subscription, {
                //   state: { isFromCreateLesson: true },
                // });
              }
            }
            setLoading(false);
          }}
        />
      )}
      <UnlimtedAccessForDialog
        open={unlimtedAccessForDialogOpen}
        setOpen={setUnlimtedAccessForDialogOpen}
      />
    </>
  );
}

export default CreateLesson;
